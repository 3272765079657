import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OfferPageComponent} from './offer-page/offer-page.component';
import {RouterModule, Routes} from '@angular/router';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  ContactApiResourceV2,
  PromotionApiResourceV2,
  PublicStructureResourceV2,
  WaitingListAPIResourceV2
} from '../generated/cronos/resources';
import {GlobalModule} from '../global/global.module';
import {ShareModule} from '../share/share.module';
import {ShareOverlayComponent} from '../share/share-overlay/share-overlay.component';
import {HidInteractionsModule} from '../hid-interactions/hid-interactions.module';
import {PublicImageResource} from '../generated/cms/resources';
import {NgxJsonLdModule} from 'ngx-json-ld';
import {FeedbackDisplayComponent} from './feedback-display/feedback-display.component';
import {FeedbackStarsModule} from '../feedback-stars/feedback-stars.module';
import {ActionLinkModule} from '../actionLinks/action-link/action-link.module';
import {AccountModule} from '../account/account.module';
import {DiscountsModule} from '../discounts/discounts.module';
import {MatButtonModule} from '@angular/material/button';
import {MatPaginatorModule} from '@angular/material/paginator';
import {OfferPageRedirectService} from './offer-page/services/offer-page-redirect-service/offer-page-redirect.service';
import {SeminarCheckoutModule} from '../checkout/seminar-checkout/seminar-checkout.module';
import {StandardCheckoutModule} from '../checkout/standard-checkout/standard-checkout.module';
import {HandleDynamicPathComponent} from './offer-page/handle-dynamic-path/handle-dynamic-path.component';
import {OfferPageRedirectComponent} from './offer-page/offer-page-redirect/offer-page-redirect.component';
import {SteuerSchlittschuhComponent} from './static-components/steuer-schlittschuh/steuer-schlittschuh.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {
  SchlittschuhSliderComponent
} from './static-components/steuer-schlittschuh/schlittschuh-slider/schlittschuh-slider.component';
import {AECDiscCheckoutModule} from "../checkout/aecdisc-checkout/aecdisc-checkout.module";
import {
  OfferPageWebinarDatesComponent
} from './static-components/offer-page-webinar-dates/offer-page-webinar-dates.component';
import {
  GlobalCheckoutComponentsModule
} from "../checkout/components/global-checkout-components/global-checkout-components.module";

const appRoutes: Routes = [
  {path: 'angebote/:routingUrl', component: OfferPageComponent},
  {path: 'offerpageredirect/:formatId', component: OfferPageRedirectComponent},
  {path: 'angebote/d/:routingUrl', component: HandleDynamicPathComponent}
];

@NgModule({
  declarations: [
    OfferPageComponent,
    FeedbackDisplayComponent,
    HandleDynamicPathComponent,
    OfferPageRedirectComponent,
    SteuerSchlittschuhComponent,
    SchlittschuhSliderComponent,
    OfferPageWebinarDatesComponent],
  imports: [
    RouterModule.forChild(appRoutes),
    CommonModule,
    BrowserModule,
    FlexModule,
    HidInteractionsModule,
    MatPaginatorModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    ShareModule,
    GlobalModule,
    NgxJsonLdModule,
    ActionLinkModule,
    FeedbackStarsModule,
    AccountModule,
    DiscountsModule,
    MatButtonModule,
    SeminarCheckoutModule,
    StandardCheckoutModule,
    AECDiscCheckoutModule,
    MatSlideToggleModule,
    GlobalCheckoutComponentsModule
  ], exports: [
        RouterModule,
        FeedbackDisplayComponent,
    ],
  entryComponents: [
    ShareOverlayComponent
  ],
  providers: [
    PromotionApiResourceV2,
    PublicStructureResourceV2,
    PublicImageResource,
    WaitingListAPIResourceV2,
    ContactApiResourceV2,
    OfferPageRedirectService
  ]
})
export class OfferPageModule {
}
